import state from './moduleBedManagementState.js'
import mutations from './moduleBedManagementMutations.js'
import actions from './moduleBedManagementActions.js'
import getters from './moduleBedManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

