import axios from '@/axios.js'

export default {
  fetchBeds ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/bed-types')
        .then(({data: response}) => {
          commit('SET_BEDS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchBed (context, bedId) {
    return new Promise((resolve, reject) => {
      axios.get(`/bed-types/${bedId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeBed (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/bed-types', payload)
           .then(res => {
             resolve(res.data)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateBed (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/bed-types/${payload.id}`, payload.body)
           .then(res => {
             resolve(res.data)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyBed ({ commit }, bedId) {
    return new Promise((resolve, reject) => {
      axios.delete(`bed/${bedId}`)
        .then((response) => {
          commit('REMOVE_RECORD', bedId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
